import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
  },
  {
    path: '/lego',
    name: 'lego',
    component: () => import(/* webpackChunkName: "lego" */ '../views/Lego.vue'),
  },
  {
    path: '/lego/pieces',
    name: 'lego-pieces',
    component: () => import(/* webpackChunkName: "lego-pieces" */ '../views/LegoPieces.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
