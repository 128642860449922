import axios from 'axios';

let accessToken;
const obtainAccessToken = async () => {
  try {
    const response = await axios.create({
      withCredentials: true,
      baseURL: process.env.VUE_APP_BACKEND_HOST,
    }).get('/access-token').then((res) => res.data);

    accessToken = response.token;
    axios.defaults.accessToken = accessToken;
  } catch (e) {
    window.location = process.env.VUE_APP_BACKEND_HOST;
  }
};

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    if (!accessToken) {
      await obtainAccessToken();
    }

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
);

axios.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest.retry) {
    originalRequest.retry = true;

    await obtainAccessToken();

    return axios(originalRequest);
  }

  if (error.response.status === 401 && originalRequest.retry) {
    window.location = process.env.VUE_APP_BACKEND_HOST;
  }

  return error;
});
