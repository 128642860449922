import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

if (process.env.NODE_ENV !== 'development') {
  Bugsnag.start({
    apiKey: '4a93c04a58eb156bc6a640411854ade9',
    plugins: [new BugsnagPluginVue()],
  });
}

export default process.env.NODE_ENV !== 'development' ? Bugsnag.getPlugin('vue') : null;
