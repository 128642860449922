import { createApp } from 'vue';
import vuetify from './plugins/vuetify';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import { loadFonts } from './plugins/webfontloader';
import bugsnag from './plugins/bugsnag';

loadFonts();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(vuetify);

if (bugsnag) {
  app.use(bugsnag);
}

app.mount('#app');
